<template>
  <base-section id="k-d-a-tech-unreal-engine" class="white" top-inner-shadow space="80">
    <v-container class="pa-0 my-10 my-md-16">
      <v-row no-gutters>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="2" md="2" />
        <v-col cols="12" lg="4" md="4" class="mx-3" style="margin: auto">
          <div>
            <div class="kda-ts-46pt wt-extrabold">{{ m_strTechnology }}</div>
            <div class="kda-ts-16pt nunito wt-regular mt-4 mt-md-4">{{ m_strDescription }}</div>
            <div class="center mt-4 mt-md-4 cursor-pointer">
              <base-img
                :src="require('@/assets/icons/icon-arrow-right.png')"
                class="mr-3"
                max-height="36px"
                max-width="36px"
                contain
              />
              <div class="kda-ts-16pt nunito wt-semibold" style="align-self: center">
                {{ m_strLink }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="1" md="1" />
        <v-col
          cols="12"
          lg="3"
          md="3"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="imageSrc"
              :class="$vuetify.breakpoint.xsOnly ? 'top' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="1" md="1" />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: 'KDATechUnrealEngine',

  data: () => ({
    m_strTechnology: 'UNREAL ENGINE',
    m_strDescription:
      'Game Engine dari Epic ini mampu menghasilkan framerate yang stabil, grafik yang bagus, shadow, depth of field, dan mendukung DX 11.',
    m_strLink: 'Selengkapnya tentang UNREAL ENGINE'
  }),

  computed: {
    imageSrc() {
      return require('@/assets/photos/tech-list-1.png');
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
      margin-top: 50px;
    }
  }
}
</style>
